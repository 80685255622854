<template>
    <div class="credit-list-page">

        <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="积分">
                <a-tabs type="card" default-active-key="1">
                    <a-tab-pane key="1" tab="学员积分" style="width: 100%;">
                        <student-credit-list></student-credit-list>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="积分记录" force-render>
                        <StudentCreditLogs></StudentCreditLogs>
                    </a-tab-pane>
                </a-tabs>
            </a-tab-pane>
            <a-tab-pane key="2" tab="积分商城" force-render>
                <a-tabs default-active-key="1">
                    <a-tab-pane key="1" tab="商品列表">
                        <credit-goods-list></credit-goods-list>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="兑换记录" force-render>
                        <CreditGoodsExchangeLogs></CreditGoodsExchangeLogs>
                    </a-tab-pane>
                </a-tabs>
            </a-tab-pane>
            <a-tab-pane key="3" tab="积分规则">
                <CreditRuleList></CreditRuleList>
            </a-tab-pane>
        </a-tabs>


    </div>
</template>
<script>
import StudentCreditList from "./StudentCreditList.vue";
import CreditGoodsList from "./CreditGoodsList"
import CreditGoodsExchangeLogs from "./CreditGoodsExchangeLogs"
import CreditRuleList from "./CreditRuleList"
import StudentCreditLogs from "./StudentCreditLogs"


export default {
    name: 'CreditList',
    components: {
        StudentCreditList,
        CreditGoodsList,
        CreditGoodsExchangeLogs,
        CreditRuleList,
        StudentCreditLogs
    },
    data() {
        return {
            loading: false,
        }
    },
    created() {
    },
    mounted() {
        let domHeight = document.documentElement.clientHeight
        this.clientH = domHeight - 227 + 'px'
    },
    methods: {}
}
</script>
<style scoped lang="less">
.credit-list-page {
    margin: 10px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
    border-radius: 4px;

    overflow-y: auto;
    user-select: none;
    color: #666666;
    padding: 15px;


    .pagination-row {
        text-align: right;
    }


}


</style>
