<template>
    <div class="credit-goods-exchange-log" style="width: 1720px">
        <div class="page-search-form">
            <a-form layout="inline" :form="searchForm" @submit="searchSubmit">
                <a-form-item layout="inline" label="时间">
                    <a-select default-value="all" v-model="searchForm.timeRange" style="width: 120px">
                        <a-select-option :value="item.value" v-for="(item,index) in timeRangeOptions" :key="index">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item layout="inline" label="发货状态">
                    <a-select default-value="all" v-model="searchForm.State" style="width: 120px">
                        <a-select-option :value="item.value" v-for="(item,index) in orderStateOptions" :key="index">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </div>
        <a-divider/>
        <a-table
            :columns="columns"
            :dataSource="tableData"
            :pagination="false"
            :customRow="rowClick"
            :scroll="{ x: '100%' }"
            @change="handleTableChange"
            style="margin-top:10px;border:1px solid #efefef"
        >
            <a slot="Student" slot-scope="text">{{ text.Name }}</a>
            <span slot="Address" slot-scope="row" @click="openAddress(row)" class="link-text">查看</span>
            <a slot="ExpressNo" slot-scope="row"  class="link-text">{{ row }}</a>
            <a slot="OperateAction" slot-scope="columnsInfo">
                <span @click="orderSend(columnsInfo)" class="link-text">发货</span>
            </a>
        </a-table>

        <div class="pagination-row">
            <a-pagination
                show-quick-jumper
                :default-current="2"
                :total="500"
                disabled
                show-less-items
                @change="pageChange"
            />
        </div>


        <a-modal v-model="showAddressModal" title="收货地址" @ok="showAddressModal=false" style="min-width: 500px">
            <a-row>
                <a-col :span="6">联系人</a-col>
                <a-col :span="18">{{operateAddress.Name}}</a-col>
            </a-row>
            <a-row>
                <a-col :span="6">联系电话</a-col>
                <a-col :span="18">{{operateAddress.Phone}}</a-col>
            </a-row>
            <a-row>
                <a-col :span="6">地址</a-col>
                <a-col :span="18">{{operateAddress.Province}}-{{operateAddress.City}}-{{operateAddress.Area}} - {{operateAddress.Detail}}</a-col>
            </a-row>
        </a-modal>


        <a-modal v-model="showSendModal" title="发货" @ok="showSendModal=false" style="min-width: 500px">
            <a-form-model :model="orderSendForm" :label-col="{span:6}" :wrapper-col="{span:16}">
                <a-form-model-item label="发货单号" required>
                    <a-input v-model="orderSendForm.ExpressNo" placeholder="请输入发货单号" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>

export default {
    name: 'CreditGoodsExchangeLogs',
    components: {},
    data() {
        return {
            loading: false,
            showAddressModal:false,
            showSendModal:false,

            searchForm: {
                contractBelong: "all",
                contractState: null,
            },
            orderSendForm:{},

            timeRangeOptions: [
                {
                    label: '全部',
                    value: "all"
                },
                {
                    label: '今天',
                    value: "today"
                },
                {
                    label: '昨天',
                    value: "yesterday"
                },
                {
                    label: '本周',
                    value: "week"
                },
                {
                    label: '本月',
                    value: "moon"
                }
            ],
            orderStateOptions: [
                {
                    label: '全部',
                    value: "all"
                },
                {
                    label: '待付款',
                    value: "1"
                },
                {
                    label: '待发货',
                    value: "2"
                },

                {
                    label: '待收货',
                    value: "3"
                },
                {
                    label: '待评价',
                    value: "4"
                },
                {
                    label: '已取消',
                    value: "5"
                },
                {
                    label: '已完结',
                    value: "6"
                },
            ],
            columns: [
                {
                    title: '会员名称',
                    dataIndex: 'Student',
                    scopedSlots: {customRender: 'Student'},
                    width: '180px',
                },
                {
                    title: '兑换商品',
                    dataIndex: 'CreditGoods',
                    sorter: true,
                    width: '250px',
                    scopedSlots: {customRender: 'CreditGoods'},
                },
                {
                    title: '兑换积分',
                    dataIndex: 'PayCredit',
                    width: '100px',
                },
                {
                    title: '支付金额',
                    dataIndex: 'PayAmount',
                    width: '100px',
                },
                {
                    title: '支付流水号',
                    dataIndex: 'transNo',
                    width: '220px',
                },
                {
                    title: '收件信息',
                    dataIndex: 'Address',
                    scopedSlots: {customRender: 'Address'},
                    width: '100px',

                },
                {
                    title: '发货单号',
                    dataIndex: 'ExpressNo',
                    scopedSlots: {customRender: 'ExpressNo'},
                    width: '220px',
                },
                {
                    title: '操作',
                    scopedSlots: {customRender: 'OperateAction'},
                    fixed: 'right',
                    width: '100px',

                },

            ],

            tableData: [
                {
                    Student:{
                        Name:"汪乐"
                    },
                    Address:{
                        Name:"汪乐",
                        Phone:'13852652415',
                        Province:"江苏",
                        City:"南京",
                        Area:"雨花台区",
                        Detail: '铁心桥街道晓村合苑一期2栋2单元'
                    },
                    ExpressNo:'ABC4565481224545'
                }
            ],


            operateAddress:{

            },


        }
    },
    created() {
    },
    mounted() {
        let domHeight = document.documentElement.clientHeight
        this.clientH = domHeight - 227 + 'px'
    },
    methods: {
        routerBtn(i) {
            this.index = i
            let that = this
            that.$router.push({})
        },

        searchSubmit() {

        },

        handleTableChange() {

        },


        pageChange() {

        },


        // 点击table
        rowClick(record, index) {
            return {
                on: {
                    click: () => {

                        console.log(record, index)
                    }
                }
            }
        },

        orderSend(row){
            console.log(row)
            this.showSendModal=true
        },
        openAddress(row){
            console.log(row)
            this.operateAddress=row
            console.log(this.operateAddress)
            this.showAddressModal=true
        },

    }
}
</script>
<style scoped lang="less">
.contract-manage-list-page {
    margin: 10px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
    border-radius: 4px;

    overflow-y: auto;
    user-select: none;
    color: #666666;
    padding: 15px;


    .pagination-row {
        text-align: right;
    }


}


.contract-detail-drawer {
    .contract-detail-head-row {
        .contract-head-col-icon {
            width: 64px;
            height: 64px;
            margin-right: 10px;

            img {
                width: 64px;
                height: 64px;
            }
        }

        .contract-drawer-btn-group {
            margin-top: 20px;

            .contract-drawer-group-btn {
                margin-left: 10px;
            }
        }
    }

    .contract-detail-brief-row {
        display: flex;
        justify-items: center;
        justify-content: space-around;

        .contract-detail-brief-col {
            text-align: center;
        }
    }

    .contract-detail-tab-menu {
        width: 100%;
    }
}


.link-text{
    color:#108ee9;
}
</style>
