<template>
    <div class="credit-goods-list-page" :style="{'max-width':domWidth }">

        <div class="page-search-form" style="text-align: right;width: 90%">
            <a-button type="primary" @click="nav2AddCreditGoods()">添加礼品</a-button>
        </div>
        <a-divider/>
        <div>
            <a-table
                :rowKey="record => record.id"
                :columns="columns"
                :data-source="tableData"
                :loading="loading"
                @change="handleTableChange"
            >
                <div slot="CreditGoods" slot-scope="row" @click="editCreditGoods(row)">
                    <a-row>
                        <a-col :span="8"><a-avatar shape="square" :src="row.thumb" :size="48" icon="user" /> </a-col>
                        <a-col :span="16">
                           <p>{{row.GoodsName}}</p>
                            <p>{{row.CreditPrice}}积分</p>
                        </a-col>
                    </a-row>
                </div>
                <div slot="SortAction"  slot-scope="row" >
                    <a-row>
                        <a-col :span="4"></a-col>
                        <a-col :span="8"><a-icon style="color: #108ee9;font-size: 18px;font-weight: bold" type="sort-ascending"  @click="creditGoodsSort(row ,'ASC')"> </a-icon></a-col>
                        <a-col :span="8"><a-icon style="color: #108ee9;font-size: 18px;font-weight: bold" type="sort-descending" @click="creditGoodsSort(row ,'DESC')"> </a-icon></a-col>
                        <a-col :span="4"></a-col>
                    </a-row>
                </div>
                <div slot="SaleAction"  slot-scope="row" >
                    <a-switch default-checked @change="goodsSaleChange(row)"  />
                </div>
                <div slot="OperateAction"  slot-scope="row" >
                    <a-row>
                        <a-col :span="8"> <a  style="color:  #108ee9"  @click="editCreditGoods(row)">编辑</a></a-col>
                        <a-col :span="4"></a-col>
                        <a-col :span="8">   <a  style="color:  red"  @click="delCreditGoods(row)">删除</a></a-col>
                    </a-row>
                </div>
            </a-table>
        </div>
        <div class="pagination-row">
            <a-pagination
                show-quick-jumper
                :default-current="2"
                :total="500"
                disabled
                show-less-items
                @change="pageChange"
            />
        </div>
    </div>
</template>
<script>

export default {
    name: 'CreditGoodsList',
    components: {

    },
    data() {
        return {
            loading:false,
            domWidth:'',





            searchForm:{
                contractBelong:"all",
                contractState:null,
            },

            columns : [
                {
                    title: '礼品',
                    dataIndex: 'CreditGoods',
                    sorter: true,
                    width: '250px',
                    scopedSlots: { customRender: 'CreditGoods' },
                },
                {
                    title: '关联产品',
                    dataIndex: 'GoodsName',
                    width: 'auto',
                },
                {
                    title: '可兑数量',
                    dataIndex: 'ReleaseNum',
                },
                {
                    title: '已兑数量',
                    dataIndex: 'exchangeNum',
                },
                {
                    title: '上下架',
                    dataIndex: 'OnSale',
                    scopedSlots: { customRender: 'SaleAction' },
                },
                {
                    title: '排序',
                    scopedSlots: { customRender: 'SortAction' },
                    width: '120px'
                },
                {
                    fixed: 'right',
                    title: '操作',
                    scopedSlots: { customRender: 'OperateAction' },
                    width: '120px'
                },
            ],

            tableData:[
                {
                    key:'1d5e3c6a',
                    id:'1d5e3c6a',
                    CreditGoods:{
                        thumb:'',
                        GoodsName:'七田真闪卡',
                        CreditPrice:100,
                    }
                }
            ],




        }
    },
    created() {
    },
    mounted() {
        let domHeight = document.documentElement.clientHeight
        let domWidth = document.documentElement.clientWidth
        this.clientH = domHeight - 227 + 'px'
        this.domWidth = domWidth -150 + 'px'
    },
    methods: {
        routerBtn(i) {
            this.index = i
            let that = this
            that.$router.push({

            })
        },

        searchSubmit(){

        },


        nav2AddCreditGoods(){
            this.$router.push({path: '/customCreditGoodsCreate'})

        },

        handleTableChange(){

        },


        pageChange(){

        },

        editCreditGoods(){
            this.$router.push({path: '/customCreditGoodsCreate'})
        },
        goodsSaleChange(row){
            console.log(row)
        },
        creditGoodsSort(row,type){
            console.log(row)
            console.log(type)
        },

        delCreditGoods(row){
            this.$confirm({
                title: '删除积分商品',
                content: '确认删除该积分商品？删除后将无法恢复数据',
                onOk() {

                },
                onCancel() {
                },
            });
        }
    }
}
</script>
<style scoped lang="less">
.credit-goods-list-page {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
    border-radius: 4px;

    overflow-y: auto;
    user-select: none;
    color: #666666;
    padding: 15px;



    .pagination-row{
        text-align: right;
    }


}


.contract-detail-drawer{
    .contract-detail-head-row {
        .contract-head-col-icon {
            width: 64px;
            height: 64px;
            margin-right: 10px;
            img {
                width: 64px;
                height: 64px;
            }
        }
        .contract-drawer-btn-group{
            margin-top: 20px;
            .contract-drawer-group-btn{
                margin-left: 10px;
            }
        }
    }
    .contract-detail-brief-row{
        display: flex;
        justify-items: center;
        justify-content: space-around;
        .contract-detail-brief-col{
            text-align: center;
        }
    }
    .contract-detail-tab-menu{
        width: 100%;
    }
}

</style>
