<template>
    <div class="student-credit-list" :style="{'max-width':domWidth }" >
        <div class="page-search-form">
            <a-form layout="inline" :form="searchForm"  @submit="searchSubmit"  style="text-align: right">
                <a-form-item layout="inline" label="">
                    <a-input-search  v-model="searchForm.keywords" style="width: 250px" placeholder="请输入客户名称或电话" enter-button @search="searchSubmit" />
                </a-form-item>
            </a-form>
        </div>
        <a-divider/>
        <div>
            <a-table
                :rowKey="record => record.id"
                :columns="columns"
                :data-source="tableData"
                :loading="loading"
                @change="handleTableChange"
            >
                <a slot="student" style="color:  #108ee9" slot-scope="text,row" @click="contractDrawerOpen(row)">{{ row.student.name }}</a>
                <div  slot="option"  slot-scope="index,row">
                    <a-menu
                        style="width: 256px"
                        :default-selected-keys="['1']"
                        :open-keys.sync="openOptionKeys"
                        mode="inline"
                        @click="handleOptionClick"
                    >
                        <a-sub-menu key="sub1" @titleClick="titleClick">
                            <span slot="title"><a-icon type="setting" />  <span>操作</span></span>
                            <a-menu-item key="AddCredit" @click="menuAddCreditClick($event,row)">增加积分</a-menu-item>
                            <a-menu-item key="ExchangeReward" @click="menuExchangeRewardClick($event,row)">兑换礼品
                            </a-menu-item>

                        </a-sub-menu>
                    </a-menu>
                </div>

            </a-table>
        </div>
        <div class="pagination-row">
            <a-pagination
                show-quick-jumper
                :default-current="2"
                :total="500"
                disabled
                show-less-items
                @change="pageChange"
            />
        </div>



        <a-modal
            title="增加积分"
            :visible="showAddCreditForm"
            :dialog-style="{ }"
            @cancel="showAddCreditForm=false"
            @ok="addCreditSubmit"

        >
            <a-row>
                <a-form-model :form="addCreditForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="addCreditSubmit">
                    <a-form-model-item label="客户" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-select placeholder="选择客户" default-value="" :disabled="true"  v-model="addCreditForm.studentId" >
                            <a-select-option value="null"  >未选择</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item  label="积分类目" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-select placeholder="选择积分类目"  v-model="addCreditForm.addType" >
                            <a-select-option value="null">未选择</a-select-option>
                            <a-select-option value="1">扫码签到</a-select-option>
                            <a-select-option value="2">好友推广</a-select-option>
                        </a-select>
                    </a-form-model-item >
                    <a-form-model-item  label="分值" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <span> 5 分</span>
                    </a-form-model-item>
                </a-form-model>
            </a-row>
        </a-modal>


        <a-modal
            title="兑换奖品"
            :visible="showExchangeRewardForm"
            :dialog-style="{ }"
            @cancel="showExchangeRewardForm=false"
            @ok="exchangeRewardSubmit"

        >
            <a-row>
                <a-form-model :form="exchangeRewardForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="exchangeRewardSubmit">
                    <a-form-model-item label="客户" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-select placeholder="选择客户" default-value=""  :disabled="true"  v-model="exchangeRewardForm.updateLessonType" >
                            <a-select-option value="null" >未选择</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item  label="修改类型" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                        <a-select placeholder="选择修改类型" default-value="" v-model="exchangeRewardForm.rewardGoodsId" >
                            <a-select-option value="null">未选择</a-select-option>
                            <a-select-option value="1" >跳跳棋</a-select-option>
                        </a-select>
                    </a-form-model-item >
                </a-form-model>
            </a-row>
        </a-modal>
    </div>
</template>
<script>

export default {
    name: 'StudentCreditList',
    components: {

    },
    data() {
        return {
            domWidth:"",
            loading:false,
            contractFreezingLoading:false,
            addCommissionUserLoading:false,

            showExchangeRewardForm:false,
            showAddCreditForm:false,





            searchForm:{
                keywords:"",
            },

            exchangeRewardForm:{

            },
            addCreditForm:{

            },


            openOptionKeys:['optionSunMenu'],


            columns : [
                {
                    title: '学员',
                    dataIndex: 'student',
                    sorter: true,
                    width: '150px',
                    scopedSlots: { customRender: 'student' },
                    fixed: 'left'
                },
                {
                    title: '联系电话',
                    dataIndex: 'phone',
                    width: '180px',
                },
                {
                    title: '状态',
                    dataIndex: 'state',
                    width: '180px',
                },
                {
                    title: '积分余额',
                    dataIndex: 'creditBalance',
                    width: '180px',
                },
                {
                    title: '累计加分',
                    dataIndex: 'addAll',
                    width: '180px',
                },
                {
                    title: '累计减分',
                    dataIndex: 'subAll',
                    width: '180px',
                },
                {
                    title: '累计兑换',
                    dataIndex: 'exchangeAll',
                    width: '180px',
                },
                {
                    title: '操作',
                    dataIndex: 'option',
                    scopedSlots: { customRender: 'option' },
                    width: '180px',
                    fixed: 'right',
                },

            ],

            tableData:[
                {
                    id:"de56a2c3",
                    key:'de56a2c3',
                    index:0,
                    student:{
                        name:'Mr 张',
                        studentId :"df4e4fg45g4rd5fsafef58fe8e6"
                    },
                    collapsed: false,  //操作栏收起
                }
            ],




        }
    },
    created() {
    },
    mounted() {
        let domHeight = document.documentElement.clientHeight
        let domWidth = document.documentElement.clientWidth
        this.clientH = domHeight - 227 + 'px'
        this.domWidth = domWidth -150 + 'px'
        console.log(domWidth)
    },
    methods: {
        routerBtn(i) {
            this.index = i
            let that = this
            that.$router.push({

            })
        },

        searchSubmit(){

        },
        exchangeRewardSubmit(){
            this.$message.success('兑换成功');
            this.showExchangeRewardForm=false
            // this.$message.error('This is an error message');
        },

        addCreditSubmit(){
            this.$message.success('积分增加成功');
            this.showAddCreditForm=false
           // this.$message.error('This is an error message');
        },

        handleOptionClick(){

        },
        titleClick(event){
            console.log('titleClick', event);

        },

        pageChange(){

        },

        handleTableChange(){

        },






        menuAddCreditClick(e,row){
            console.log(e.index)
            this.showAddCreditForm=true

            console.log(row)
        },
        menuExchangeRewardClick(e,row){
            console.log(e.index)
            this.showExchangeRewardForm=true
            console.log(row)
        },





    }
}
</script>
<style scoped lang="less">
.contract-manage-list-page {
    margin: 10px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
    border-radius: 4px;

    overflow-y: auto;
    user-select: none;
    color: #666666;
    padding: 15px;



    .pagination-row{
        text-align: right;
    }


}


.contract-detail-drawer{
    .contract-detail-head-row {
        .contract-head-col-icon {
            width: 64px;
            height: 64px;
            margin-right: 10px;
            img {
                width: 64px;
                height: 64px;
            }
        }
        .contract-drawer-btn-group{
            margin-top: 20px;
            .contract-drawer-group-btn{
                margin-left: 10px;
            }
        }
    }
    .contract-detail-brief-row{
        display: flex;
        justify-items: center;
        justify-content: space-around;
        .contract-detail-brief-col{
            text-align: center;
        }
    }
    .contract-detail-tab-menu{
        width: 100%;
    }
}

</style>
