<template>
    <div :style="{'max-width':'1720px' }">
        <div class="page-search-form">
            <a-form layout="inline" :form="searchForm"  @submit="searchSubmit"  style="text-align: right">
                <a-form-item layout="inline" label="">
                    <a-input-search  v-model="searchForm.keywords" style="width: 250px" placeholder="请输入客户名称或电话" enter-button @search="searchSubmit" />
                </a-form-item>
            </a-form>
        </div>
        <div>
            <a-table
                :rowKey="record => record.id"
                :columns="columns"
                :data-source="tableData"
                :loading="loading"

            >
                <a slot="ContractNo" style="color:  #108ee9" slot-scope="row" @click="contractDrawerOpen(row)">{{ row }}111</a>
            </a-table>
        </div>
        <div class="pagination-row">
            <a-pagination
                show-quick-jumper
                :default-current="2"
                :total="500"
                disabled
                show-less-items
                @change="pageChange"
            />
        </div>
    </div>
</template>
<script>

export default {
    name: 'StudentCreditLogs',
    components: {

    },
    data() {
        return {
            domWidth:"",
            loading:false,


            searchForm:{
              keywords:''
            },


            columns : [
                {
                    title: '变更时间',
                    dataIndex: 'CreateTime',
                    width: '10%',
                },
                {
                    title: '变更',
                    dataIndex: 'changeType',
                },
                {
                    title: '客户',
                    dataIndex: 'CustomName',
                    width: 'auto',
                },

                {
                    title: '积分类目',
                    dataIndex: 'CreditType',
                },
                {
                    title: '积分变更',
                    dataIndex: 'CreditNum',
                },
                {
                    title: '剩余积分',
                    dataIndex: 'Balance',
                },

                {
                    title: '操作人',
                    dataIndex: 'OperateUser',
                },

            ],

            tableData:[
                {
                    key:'de56a2c3',
                    CreateTime:"2022-11-20 13:00:01",
                    OperateUser:'平台结算',
                }
            ],




        }
    },
    created() {
    },
    mounted() {
        let domHeight = document.documentElement.clientHeight
        this.clientH = domHeight - 227 + 'px'
        let domWidth = document.documentElement.clientWidth
        this.domWidth = (domWidth -150 )+ 'px'
        console.log(domWidth)
    },
    methods: {
        routerBtn(i) {
            this.index = i
            let that = this
            that.$router.push({

            })
        },

        searchSubmit(){

        },

        pageChange(){

        },

    }
}
</script>
<style scoped lang="less">


</style>
