<template>
    <div class="contract-manage-list-page" :style="{'max-width':domWidth }">

        <div class="head-action-row">
            <a-button type="primary" @click="openEditRulesForm(null,'add')"> 添加</a-button>
        </div>
        <a-divider/>
        <div>
            <a-table
                :columns="columns"
                :data-source="tableData"
                :loading="loading"
                @change="handleTableChange"
            >
                <a slot="ContractNo" style="color:  #108ee9" slot-scope="row" @click="contractDrawerOpen(row)">{{ row }}111</a>
                <div slot="State" style="color:  #108ee9" slot-scope="text, record">
                    <a-switch default-checked @change="onStateChange(record)"  />
                </div>

                <div slot="OperateAction" style="color:  #108ee9" slot-scope="text, record">
                    <a default-checked @click="openEditRulesForm(record,'edit')" class="link-text" > 编辑</a>
                </div>
            </a-table>
        </div>
        <div class="pagination-row">
            <a-pagination
                show-quick-jumper
                :default-current="2"
                :total="500"
                disabled
                show-less-items
                @change="pageChange"
            />
        </div>

        <a-modal v-model="showEditRuleForm" title="编辑规则" @ok="editRulesSubmit" style="min-width: 500px">
            <a-form-model :model="editRulesForm" :label-col="{span:6}" :wrapper-col="{span:16}">
                <a-form-model-item label="积分规则" :required="editRulesType==='add'">
                    <a-input v-model="editRulesForm.Key" placeholder="请输入积分规则key" :disabled="editRulesType==='edit'" />
                </a-form-model-item>
                <a-form-model-item label="规则名称" required>
                    <a-input v-model="editRulesForm.Name" placeholder="请输入规则名称" />
                </a-form-model-item>
                <a-form-model-item label="积分" required>
                    <a-input v-model="editRulesForm.CreditNum" placeholder="请输入积分" />
                </a-form-model-item>
                <a-form-model-item label="简介" required>
                    <a-input v-model="editRulesForm.Desc" placeholder="请输入规则简介" />
                </a-form-model-item>
                <a-form-model-item label="状态" required>
                    <a-switch default-checked @change="onStateChange(editRulesForm)" />
                </a-form-model-item>

            </a-form-model>
        </a-modal>
    </div>
</template>
<script>

export default {
    name: 'StudentCreditList',
    components: {

    },
    data() {
        return {
            loading:false,
            showEditRuleForm:false,
            domWidth:'',
            editRulesType :"add", //添加 add  编辑：edit


            searchForm:{
                contractBelong:"all",
                contractState:null,
            },

            editRulesForm:{

            },


            columns : [
                {
                    title: '规则名称',
                    dataIndex: 'Name',
                    width: 'auto',
                },
                {
                    title: '规则',
                    dataIndex: 'Key',
                    width: 'auto',
                },
                {
                    title: '积分',
                    dataIndex: 'CreditNum',
                },
                {
                    title: '简介',
                    dataIndex: 'Desc',
                },
                {
                    title: '状态',
                    dataIndex: 'State',
                    scopedSlots: {customRender: 'State'},
                },
                {
                    title: '操作',
                    scopedSlots: {customRender: 'OperateAction'},
                    fixed: 'right',
                    width: '100px',

                },
            ],

            tableData:[
                {
                    Id:'58ad20d53hj788gee2',
                    Name:"会员活动扫码签到",
                    Key:"UserScanSignIn",
                    Desc:'会员活动扫码签到员工企业微信应用内扫码',
                    CreditNum:5,
                    State:true,
                }

            ],




        }
    },
    created() {
    },
    mounted() {
        let domHeight = document.documentElement.clientHeight
        this.clientH = domHeight - 227 + 'px'


        let domWidth = document.documentElement.clientWidth
        this.domWidth = domWidth -150 + 'px'
    },
    methods: {
        routerBtn(i) {
            this.index = i
            let that = this
            that.$router.push({

            })
        },

        searchSubmit(){

        },

        handleTableChange(){

        },


        pageChange(){

        },

        onStateChange(row){
            console.log(row)
        },

        openEditRulesForm(row,editType){
            console.log(row)
            console.log(editType)
            this.editRulesType=editType
            this.showEditRuleForm=true
            if (editType==='add'){
                this.editRulesForm={}
            }
            if (editType==='edit'){
                this.editRulesForm=  row
            }
        },
        editRulesSubmit(){
            this.showEditRuleForm=false

        }
    }
}
</script>
<style scoped lang="less">
.contract-manage-list-page {
    margin: 10px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
    border-radius: 4px;

    overflow-y: auto;
    user-select: none;
    color: #666666;
    padding: 15px;



    .pagination-row{
        text-align: right;
    }


}


.contract-detail-drawer{
    .contract-detail-head-row {
        .contract-head-col-icon {
            width: 64px;
            height: 64px;
            margin-right: 10px;
            img {
                width: 64px;
                height: 64px;
            }
        }
        .contract-drawer-btn-group{
            margin-top: 20px;
            .contract-drawer-group-btn{
                margin-left: 10px;
            }
        }
    }
    .contract-detail-brief-row{
        display: flex;
        justify-items: center;
        justify-content: space-around;
        .contract-detail-brief-col{
            text-align: center;
        }
    }
    .contract-detail-tab-menu{
        width: 100%;
    }
}

.link-text{
    color:#108ee9;
}

</style>
